import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import { FilterFilled } from '@ant-design/icons';

const Programs = ({ degree, programs, doctorate }) => {
  const [count, setCount] = useState(programs.length);

  if (degree === "Master's Degree" && doctorate && doctorate.length > 0) {
    programs.forEach((prog) => {
      const foundDoctorate = doctorate.find((doc) => doc.cipcode === prog.cipcode);
      if (foundDoctorate) {
        prog.hasDoctorate = true;
        prog.doctorateConferrals = foundDoctorate.conferrals;
      }
    });
  }

  const degree_columns =
    degree !== "Master's Degree"
      ? [
          {
            title: 'PAYBACK',
            dataIndex: ['Payback'],
            key: 'Payback',
            render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
          },
        ]
      : [
          {
            title: 'DEBT',
            dataIndex: ['debt'],
            key: 'debt',
            render: (value) => (value ? value : 'N/A'),
            sorter: (a, b) => {
              if (!a.debt) {
                return 1;
              } else if (!b.debt) {
                return -1;
              } else {
                return a.debt - b.debt;
              }
            },
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'DEBT TO EARN RATIO',
            dataIndex: ['earnDebtRatio'],
            key: 'earnDebtRatio',
            render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
          },
          {
            title: 'MONTHLY DEBT PAYMENT',
            dataIndex: ['DEBT_ALL_STGP_EVAL_MDN10YRPAY'],
            key: 'DEBT_ALL_STGP_EVAL_MDN10YRPAY',
            render: (value) => (value ? value : 'N/A'),
          },
        ];

  const columns = [
    {
      title: 'CIPCODE',
      dataIndex: 'cipcode',
      key: 'cipcode',
      render: (text, record) => {
        if (record.businessType) {
          return (
            <Tooltip
              title={`${
                record.businessType === 'part_time' ? 'Part Time' : record.businessType === 'exec' ? 'Executive' : 'N/A'
              }`}
              color='red'
            >
              {text}
            </Tooltip>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: 'PROGRAM NAME',
      dataIndex: ['title'],
      key: 'title',
      render: (text, record) => {
        if (record.businessType) {
          return (
            <Tooltip
              title={`${
                record.businessType === 'part_time' ? 'Part Time' : record.businessType === 'exec' ? 'Executive' : 'N/A'
              }`}
              color='red'
            >
              {text}
            </Tooltip>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: 'CONFERRALS',
      dataIndex: ['conferrals'],
      key: 'conferrals',
      filters: [
        {
          text: '> 0',
          value: '> 0',
        },
      ],
      onFilter: (value, record) => record.conferrals && record.conferrals > 0,
      render: (value) => (value || value === 0 ? value : 'N/A'),
    },
    {
      title: 'EARNINGS',
      dataIndex: ['EARN'],
      key: 'EARN',
      render: (value, record) => {
        if (record.EARN_USED) {
          return (
            <Tooltip title={`${record.EARN_USED} earnings`} color='blue' placement='left'>
              {value ? value.toFixed(0) : 'N/A'}
            </Tooltip>
          );
        }
        return value ? value.toFixed(0) : 'N/A';
      },
      sorter: (a, b) => {
        if (!a.EARN) {
          return 1;
        } else if (!b.EARN) {
          return -1;
        } else {
          return b.EARN - a.EARN;
        }
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'EARN COUNT',
      dataIndex: ['EARN_COUNT'],
      key: 'EARN_COUNT',
      render: (value) => (value ? value : 'N/A'),
    },
    ...degree_columns,
    {
      title: 'EARNINGPLUS',
      dataIndex: ['degreePremium', 'premiumAmount'],
      key: 'premiumAmount',
      render: (value) => (value ? value.toFixed(0) : 'N/A'),
    },
    {
      title: 'EARNINGPLUS %',
      dataIndex: ['degreePremium', 'premiumPercentage'],
      key: 'premiumPercentage',
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
    },
    {
      title: 'ECONOMIC SCORE',
      dataIndex: ['degreePremium', 'rankingScore'],
      key: 'rankingScore',
      filters: [
        {
          text: 'Non N/A',
          value: 'Non N/A',
        },
      ],
      onFilter: (value, record) => record.degreePremium && record.degreePremium.rankingScore,
      filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? 'red' : 'white', fontSize: '1rem' }} />,
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
      sorter: (a, b) => {
        if (!a.degreePremium || !a.degreePremium.rankingScore) {
          return 1;
        } else if (!b.degreePremium || !b.degreePremium.rankingScore) {
          return -1;
        } else {
          return a.degreePremium.rankingScore - b.degreePremium.rankingScore;
        }
      },
      sortDirections: ['ascend', 'descend'],
    },
  ];

  if (degree === "Master's Degree") {
    columns.push(
      {
        title: 'HAS DOCTORATE',
        dataIndex: ['hasDoctorate'],
        key: 'hasDoctorate',
        filters: [
          {
            text: 'Yes',
            value: true,
          },
        ],
        onFilter: (value, record) => record.hasDoctorate,
        filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? 'red' : 'white', fontSize: '1rem' }} />,
        render: (value) => (value ? 'Yes' : 'No'),
      },
      {
        title: 'DOCTORATE CONFERRALS',
        dataIndex: ['doctorateConferrals'],
        key: 'doctorateConferrals',
        render: (value) => (value ? value : 'N/A'),
      },
    );
  }

  const onChangeTable = (pagination, filters, sorter, extra) => {
    setCount(extra.currentDataSource.length);
  };

  return (
    <div className='panel-body'>
      <p>Number of programs: {count}</p>

      <div>
        <Table
          size='middle'
          onChange={onChangeTable}
          bordered
          dataSource={programs}
          columns={columns}
          rowKey={(record) => record.cipcode + record.credential}
          // expandable={{
          //   expandedRowRender: (record) => (
          //     <p
          //       style={{
          //         margin: 0,
          //       }}
          //     >
          //       Business{' '}
          //       {record.businessType === 'part_time'
          //         ? 'Part Time'
          //         : record.businessType === 'exec'
          //         ? 'Executive'
          //         : 'N/A'}
          //     </p>
          //   ),
          //   rowExpandable: (record) => record.businessType,
          // }}
        />
      </div>
    </div>
  );
};

export default Programs;
