import React from 'react';
import { Tabs } from 'antd';
import Programs from './Programs';

const { TabPane } = Tabs;

const AllProgramsTab = ({ majors, gradPrograms, associates, doctorate }) => {
  return (
    <div className='panel-body'>
      <Tabs defaultActiveKey={'ASSOCIATE'}>
        <TabPane tab='ASSOCIATE PROGRAMS' key='ASSOCIATE PROGRAMS'>
          <Programs programs={associates} degree="Associate's Degree" />
        </TabPane>

        <TabPane tab='MAJORS' key='MAJORS'>
          <Programs programs={majors} degree='Bachelors Degree' />
        </TabPane>

        <TabPane tab='GRAD PROGRAMS' key='GRAD PROGRAMS'>
          <Programs programs={gradPrograms} degree="Master's Degree" doctorate={doctorate} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AllProgramsTab;
