import React, { useState } from 'react';
import { Table, Input, Button, Checkbox, Tooltip } from 'antd';
import { SearchOutlined, QuestionCircleFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ReactJson from 'react-json-view';

const SORT = {
  ascend: 1,
  descend: -1,
};

const SchoolListByCipcode = ({
  list,
  degree,
  filter,
  setFilter,
  setDegree,
  sort,
  setSort,
  online,
  setOnline,
  isOnline,
  onlineTab,
  setOnlineTab,
  fullOnline,
  setFullOnline,
  cipcode,
}) => {
  const [search, setSearch] = useState({});

  let searchInput;
  const getColumnSearchProps = (dataIndex, name) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue: (filter && filter[prop] && [filter[prop]]) || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [search[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={() => handleReset(clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        if (search && search[prop]) {
          filtered = true;
        } else {
          filtered = false;
        }
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        search[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        ),
    };
  };

  const filterHandler = (prop, value) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let filterQuery = null;
    let prevFilter = {};
    if (filter) {
      prevFilter = JSON.parse(filter);
    }

    if (value !== null) {
      prevFilter[prop] = value;
      filterQuery = `${JSON.stringify({ [prop]: !isNaN(value) ? +value : [value.toString()], ...prevFilter })}`;
    } else {
      delete prevFilter[prop];
      filterQuery = `${JSON.stringify({ ...prevFilter })}`;
    }

    // HTML URL encoding => solves error: `Unexpected end of JSON input`
    if (filterQuery.includes('&')) filterQuery = filterQuery.replace('&', '%26');

    setDegree(degree);
    setOnlineTab(isOnline);
    setFilter(filterQuery);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const newSearch = { ...search };
    newSearch[dataIndex] = selectedKeys[0] ? selectedKeys[0] : search[dataIndex];
    setSearch(newSearch);
    filterHandler(dataIndex, selectedKeys[0] ? selectedKeys[0] : search[dataIndex]);
    confirm();
  };

  const handleReset = (clearFilters, prop) => {
    if (Array.isArray(prop)) prop = prop.join('.');
    const newSearch = { ...search };
    setSearch(newSearch);
    delete newSearch[prop];
    filterHandler(prop, null);
    clearFilters();
  };

  const sortHandler = (prop, order) => {
    if (Array.isArray(prop)) prop = prop.join('.');

    let sortQuery = null;
    if (order) {
      sortQuery = `${JSON.stringify({ [prop]: SORT[order] })}`;
    }

    setOnlineTab(isOnline);
    setDegree(degree);
    setSort(sortQuery);
  };

  let columns = [
    {
      title: 'RANK',
      dataIndex: ['rank'],
      key: 'rank',
    },
    {
      title: 'UNITID',
      dataIndex: ['unitid'],
      key: 'unitid',
      // ...getColumnSearchProps(['unitid']),
    },
    {
      title: 'NAME',
      dataIndex: ['name'],
      key: 'name',
      ...getColumnSearchProps(['name']),
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      ...getColumnSearchProps(['state']),
    },
    {
      title: 'CONFERRALS',
      dataIndex: 'conferrals',
      key: 'conferrals',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ONLINE/HYBRID',
      dataIndex: 'online_hybrid',
      key: 'online_hybrid',
      render: (value) => (value ? value : 'N/A'),
    },
    {
      title: 'EARNINGS',
      dataIndex: ['earnings'],
      key: 'earnings',
      render: (value) => (value && value !== -9999999 ? value.toFixed(0) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'NET ANNUAL COST',
      dataIndex: ['cost'],
      key: 'cost',
      render: (value) => (value ? value.toLocaleString('en-US') : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'EARNINGSPLUS',
      dataIndex: ['premiumAmount'],
      key: 'premiumAmount',
      render: (value) => (value && value !== -9999999 ? value.toFixed(0) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'EARNINGSPLUS %',
      dataIndex: ['premiumPercentage'],
      key: 'premiumPercentage',
      render: (value) => (value ? value.toFixed(2) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ECONOMIC SCORE',
      dataIndex: ['valueRank'],
      key: 'valueRank',
      render: (value) => (value && value !== 9999999 ? value.toFixed(2) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  if (degree === 'Bachelors Degree' || degree === "Associate's Degree") {
    columns.splice(6, 0, {
      title: 'PAYBACK',
      dataIndex: ['payback'],
      key: 'payback',
      render: (value) => (value && typeof value === 'number' && value !== 9999999 ? value.toFixed(2) : 'N/A'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    });
  } else if (degree === "Master's Degree") {
    columns.splice(
      6,
      0,
      {
        title: 'DEBT',
        dataIndex: ['debt'],
        key: 'debt',
        render: (value) => (value ? value.toFixed(0) : 'N/A'),
        sorter: true,
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'DEBT TO EARNINGS',
        dataIndex: ['earnDebtRatio'],
        key: 'earnDebtRatio',
        render: (value) => (typeof value === 'number' ? value.toFixed(2) : value),
        sorter: true,
        sortDirections: ['ascend', 'descend'],
      },
    );
  }

  const onChangeTable = (addPagination, addFilters, addSorter) => {
    // pageHandler(addPagination.current);
    sortHandler(addSorter.field, addSorter.order);
  };

  const infoText = !isOnline
    ? `Includes all ${degree.toLowerCase()} ${cipcode} cipcode programs, campus or online. Excludes fully online schools.`
    : isOnline
    ? `Includes hybrid or fully online ${degree.toLowerCase()} ${cipcode} cipcode programs only.`
    : `Error...`;

  let filters = {
    ICLEVEL: 1,
    MAIN: 1,
    UGOFFER: 1,
    PSET4FLG: 1,
    State: `NOT in: Guam, Marshall Islands, Northern Mariana Islands, Palau, Puerto Rico, Virgin Islands, American Samoa, Federated States of Micronesia, Northern Marianas`,
    carnegieClass: 'NOT 24 and -2',
    fullOnline: false,
    cipcode,
    'programs.credential': degree,
    conferralsThreshold: degree === 'Bachelors Degree' ? 10 : degree === 'Undergraduate Certificate or Diploma' ? 0 : 5,
  };

  if (isOnline) {
    delete filters.fullOnline;
    filters = {
      ...filters,
      MAIN: '1 except a few selected cases (University of Florida-Online)',
      'programs.distance': 'At least 1 online or 1 hybrid program',
    };
  }

  if (degree === "Associate's Degree") {
    filters = { ...filters, ICLEVEL: '2 or 3' };
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '50px' }}>
          <Tooltip title={infoText} placement='bottomLeft' color='red'>
            <p>
              <strong>INFO</strong>
              <QuestionCircleFilled />
            </p>
          </Tooltip>
        </div>

        <strong>FILTERS: </strong>
        <ReactJson
          name={null}
          src={filters}
          collapsed={true}
          displayDataTypes={false}
          enableClipboard={false}
          style={{ fontSize: '14px', marginLeft: '10px', marginRight: '10px' }}
        />

        <h3 style={{ marginRight: '50px' }}>Total Schools: {list.length}</h3>

        {!isOnline && (
          <Checkbox name='online' checked={online} onChange={(e) => setOnline(e.target.checked)}>
            Online/Hybrid program
          </Checkbox>
        )}

        {isOnline && (
          <Checkbox name='fullOnline' checked={fullOnline} onChange={(e) => setFullOnline(e.target.checked)}>
            Fully online school
          </Checkbox>
        )}
      </div>

      <div>
        <Table
          size='middle'
          onChange={onChangeTable}
          bordered
          dataSource={list}
          columns={columns}
          rowKey={(record) => record.unitid}
          pagination={{
            pageSize: 20,
            // total: total || 0,
            // current: page,
            // defaultCurrent: 1,
            // position: 'bottom',
            // showSizeChanger: false,
            // onChange: (page) => pageHandler(page),
            // hideOnSinglePage: true,
          }}
        />
      </div>
    </div>
  );
};

export default SchoolListByCipcode;
