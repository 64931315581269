import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Form,
  notification,
  Spin,
  Input,
  Button,
  Collapse,
  Image,
  Divider,
  Table,
  Switch,
  Select,
  InputNumber,
} from 'antd';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';
import { UserContext } from '../../../App';
import { useHistory } from 'react-router-dom';
import RV_OPTIONS from '../../../lib/rvOptions.json';

import Editor from '../../../components/base/autocomplete/Editor';
import CollapseImageTooltip from '../../../components/base/CollapseImageTooltip';

// images
import h1AndDesc from '../../../images for tooltips/state/h1 and desc.png';
import section1Img from '../../../images for tooltips/state/section 1.png';
import section2Img from '../../../images for tooltips/state/section 2.png';
import section2WidgetImg from '../../../images for tooltips/state/section 2 and widget.png';
import section4Img from '../../../images for tooltips/state/section 4.png';
import section5Img from '../../../images for tooltips/state/section 5.png';
import section6Img from '../../../images for tooltips/state/section 6.png';
import careerImg from '../../../images for tooltips/state/careers.png';
import exploreMoreImg from '../../../images for tooltips/state/explore more colleges.png';
import schoolListImg from '../../../images for tooltips/state/school list.png';
import shortcodeImg from '../../../images for tooltips/state/shortcode.png';
import rvBlockImg from '../../../images for tooltips/state/rv block.png';

const { TextArea } = Input;

const initialForm = {
  useNewTemplate: false,
};

function extractContentInsideHashes(inputString) {
  const regex = /#(?!\s)(\S*?)#/g;
  const matches = [];
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}

const extractCipContent = (inputString) => {
  const regexCip = /#\[(.*?)\]#/g;
  const matches = [];
  let match;

  while ((match = regexCip.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
};

const SingleStateEditPage = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const { state } = props.match.params;
  const h1Ref = useRef();

  const [working, setWorking] = useState(false);
  const [data, setData] = useState(null);
  const [suggestionList, setSuggestionList] = useState([]);
  const [wpLink, setWpLink] = useState(null);

  // widgets
  const [overview, setOverview] = useState([]);
  const [avgSalaryWidget, setAvgSalaryWidget] = useState([]);
  const [fastestGrowingCareers, setFastestGrowingCareers] = useState([]);
  const [emiSchools, setEmiSchools] = useState([]);
  const [exploreMoreSchools, setExploreMoreSchools] = useState([]);
  const [mostPopularPrograms, setMostPopularPrograms] = useState([]);
  const [bestMajors, setBestMajors] = useState([]);
  const [avgTuition, setAvgTuition] = useState([]);
  const [rvBlock, setRVBlock] = useState({
    rvCategory: null,
    rvDegree: null,
    rvSubject: null,
    rvType: null,
    rvLayout: null,
    buttonText: null,
    resultLimit: null,
    moreButtonText: null,
  });

  // dynamic content states
  const [contentDesc, setContentDesc] = useState('');
  const [section1Text, setSection1Text] = useState('');
  const [section2Text, setSection2Text] = useState('');
  const [section3Text, setSection3Text] = useState('');
  const [socialMobilityText, setSocialMobilityText] = useState('');

  // H1s
  const [h1, setH1] = useState(null);
  const [section1H1, setSection1H1] = useState(null);
  const [section2H1, setSection2H1] = useState(null);
  const [section3H1, setSection3H1] = useState(null);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [metaImage, setMetaImage] = useState(null);
  const [schoolListH1, setSchoolListH1] = useState(null);
  const [socialMobilityH1, setSocialMobilityH1] = useState(null);
  const [shortcode, setShortcode] = useState(null);

  const RV_LAYOUT = rvBlock.rvType === 'qdf' ? RV_OPTIONS.rv_qdf_layout : RV_OPTIONS.rv_el_layout;

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        setWorking(true);

        // state content data
        const resData = await Axios.get(`${SERVER_URL}/rankings-state-content/${state}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (resData && resData.data) {
          setData(resData.data);
          setWpLink(resData.data.link);
          const content = resData.data?.contentNewDesign;
          form.setFieldsValue({
            useNewTemplate: resData.data?.useNewTemplate,
          });

          // set content
          setContentDesc(content?.h1AndDesc?.text || '');
          setSection1Text(content?.section1?.text || '');
          setSection2Text(content?.section2?.text || '');
          setSection3Text(content?.section3?.text || '');
          setSocialMobilityText(content?.emi?.text || '');

          // set H1s and meta
          setH1(content?.h1AndDesc?.h1);
          setSection1H1(content?.section1?.h1);
          setSection2H1(content?.section2?.h1);
          setSection3H1(content?.section3?.h1);
          setMetaTitle(content?.meta?.metaTitle);
          setMetaDescription(content?.meta?.metaDescription);
          setMetaImage(content?.meta?.metaImage);
          setSchoolListH1(content?.schoolListH1);
          setSocialMobilityH1(content?.emi?.h1);
          setShortcode(content?.shortcodeSection?.shortcode);
          setRVBlock(content?.rvBlock ? content.rvBlock : rvBlock);

          // fetch other data for widgets
          const widgetRes = await Axios.get(`${SERVER_URL}/rankings-state-widgets/${state}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          if (widgetRes && widgetRes.data) {
            setOverview(widgetRes.data.overview);
            setAvgSalaryWidget(widgetRes.data.section1.earnings);
            setAvgTuition(widgetRes.data.section1.tuition);
            setFastestGrowingCareers(widgetRes.data.careers);
            setEmiSchools(widgetRes.data.emiSchools);
            setExploreMoreSchools(widgetRes.data.exploreMoreSchools);
            setMostPopularPrograms(widgetRes.data.mostPopularPrograms);
            setBestMajors(widgetRes.data.bestMajors);
          }

          // autocomplete vars
          const autocompleteVarsRes = await Axios.get(
            `${SERVER_URL}/rankings-state-new-content-autocomplete-vars/${state}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          if (autocompleteVarsRes && autocompleteVarsRes.data) {
            setSuggestionList(autocompleteVarsRes.data);
          }
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem with fetching ranking.',
          placement: 'bottomRight',
        });
      }
    };

    fetchRanking();
  }, []);

  const handleDynamicText = (content) => {
    let varArr = [];
    const vars = extractContentInsideHashes(content);
    const varsCips = extractCipContent(content);

    vars.forEach((el) => {
      const foundConst = suggestionList.find((item) => item.key === `#${el}#`);
      if (foundConst) {
        varArr.push(foundConst);
      }
    });
    varsCips.forEach((el) => {
      const foundConst = suggestionList.find((item) => item.key === `#[${el}]#`);
      if (foundConst) {
        varArr.push(foundConst);
      }
    });

    return varArr;
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();

    if (!h1) {
      notification.error({
        message: 'Error',
        description: 'Must input <h1> tag in "H1 and description" section',
        placement: 'bottomRight',
      });
      h1Ref.current.focus();
      return;
    }

    // vars
    const autocompleteVarsUsed = [
      ...handleDynamicText(contentDesc),
      ...handleDynamicText(section1Text),
      ...handleDynamicText(section2Text),
      ...handleDynamicText(section3Text),
      ...handleDynamicText(socialMobilityText),
    ].filter((value, index, self) => index === self.findIndex((t) => t.key === value.key));

    // final content body
    const bodyContent = {
      h1AndDesc: { h1, text: contentDesc },
      meta: { metaTitle, metaDescription, metaImage },
      overview,
      section1: { h1: section1H1, text: section1Text, widgets: { earnings: avgSalaryWidget, cost: avgTuition } },
      section2: { h1: section2H1, text: section2Text, mostPopularPrograms },
      section3: { h1: section3H1, text: section3Text, bestMajors },
      emi: { h1: socialMobilityH1, text: socialMobilityText, emiSchools },
      schoolListH1,
      fastestGrowingCareers,
      exploreMoreSchools,
      shortcodeSection: { shortcode },
      autocompleteVarsUsed,
      rvBlock,
    };

    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/rankings-state-content/${state}`,
        {
          contentNewDesign: bodyContent,
          useNewTemplate: values.useNewTemplate,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      notification.success({
        message: 'State ranking content updated',
        placement: 'bottomRight',
        duration: 2,
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with updating state ranking content.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper' style={{ marginBottom: '30px' }}>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <h4>
            <strong>
              {data?.state} ({data?.stateAbbr})
            </strong>
          </h4>

          <Button type='primary' style={{ marginRight: '8px', float: 'left' }} onClick={() => history.goBack()}>
            ALL STATE RANKINGS
          </Button>
        </div>

        <Form form={form} initialValues={initialForm} onFinish={handleSubmit} name='basic'>
          <Collapse defaultActiveKey={['h1']}>
            {/* h1 and description */}
            <Collapse.Panel
              key='h1'
              header={<CollapseImageTooltip title='H1 and description' image={h1AndDesc} height='200px' />}
              id='h1AndDescID'
            >
              <Form.Item label='<h1>'>
                <Input ref={h1Ref} value={h1} onChange={(e) => setH1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='h1AndDesc'>Description:</label>
              <Editor
                content={contentDesc}
                setContent={setContentDesc}
                constants={suggestionList}
                name='h1AndDesc'
                tooltipImage={h1AndDesc}
                elemID='h1AndDescID'
              />
            </Collapse.Panel>

            {/* meta tags */}
            <Collapse.Panel key='meta' header='Meta tags'>
              <Form.Item label='Meta Title'>
                <Input maxLength={70} value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
              </Form.Item>

              <Form.Item label='Meta Description'>
                <TextArea
                  maxLength={180}
                  rows={3}
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </Form.Item>

              <Form.Item label='Meta Image'>
                <Input value={metaImage} onChange={(e) => setMetaImage(e.target.value)} />
              </Form.Item>

              {metaImage && (
                <Image
                  src={metaImage}
                  style={{
                    width: '200px',
                    border: '0.5px solid #bbb',
                    background: '#eee',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  alt='metaImage'
                />
              )}
            </Collapse.Panel>

            {/* OVERVIEW SECTION */}
            <Collapse.Panel
              key='overview'
              header={<CollapseImageTooltip title={`Overview of ${state}`} image={section1Img} height='210px' />}
              id='overviewID'
            >
              <Table
                bordered
                dataSource={overview}
                columns={[
                  {
                    title: 'Total colleges',
                    dataIndex: ['numberOfSchools'],
                    key: 'numberOfSchools',
                  },
                  {
                    title: 'Avg. earnings bachelors degree',
                    dataIndex: ['avgEarnings'],
                    key: 'avgEarnings',
                  },
                  {
                    title: 'Avg. cost of college',
                    dataIndex: ['avgCost'],
                    key: 'avgCost',
                  },
                  {
                    title: 'Avg. student debt',
                    dataIndex: ['avgDebt'],
                    key: 'avgDebt',
                  },
                  {
                    title: "Bachelor's degree or higher",
                    dataIndex: ['bachOrHigher'],
                    key: 'bachOrHigher',
                  },
                ]}
                rowKey={(record) => 'rowKey'}
                pagination={false}
                style={{ width: '50%' }}
              />
            </Collapse.Panel>

            {/* SCHOOL LIST HEADING */}
            <Collapse.Panel
              key='list name'
              header={<CollapseImageTooltip title='School List Heading' image={schoolListImg} height='190px' />}
              id='schoolListH1'
            >
              <Form.Item label='Heading'>
                <Input value={schoolListH1} onChange={(e) => setSchoolListH1(e.target.value)} />
              </Form.Item>
            </Collapse.Panel>

            {/* RV BLOCK */}
            <Collapse.Panel
              key='RV block'
              header={
                <CollapseImageTooltip
                  title='RV Block options'
                  image={rvBlockImg}
                  height='150px'
                  imageHeight='140px'
                  imageWidth='200px'
                  width='210px'
                />
              }
              id='rvBlock'
            >
              <Form.Item label='RV Type'>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  value={rvBlock.rvType}
                  onChange={(value) => {
                    if (value === 'qdf') {
                      setRVBlock({
                        ...rvBlock,
                        rvType: value,
                        rvLayout: null,
                        resultLimit: null,
                        moreButtonText: null,
                      });
                    } else {
                      setRVBlock({ ...rvBlock, rvType: value, rvLayout: null });
                    }
                  }}
                >
                  {RV_OPTIONS.rv_data_types.map((item) => (
                    <Select.Option key={item.name} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
                  <ExclamationCircleOutlined /> {'  '}
                  Required field for WP to display the block.
                </p>
              </Form.Item>

              <Form.Item label='RV Degree'>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  value={rvBlock.rvDegree}
                  onChange={(value) => setRVBlock({ ...rvBlock, rvDegree: value })}
                >
                  {RV_OPTIONS.rv_data_degrees.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='RV Program'>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  value={rvBlock.rvCategory}
                  onChange={(value) => setRVBlock({ ...rvBlock, rvCategory: value })}
                >
                  {RV_OPTIONS.rv_data_categories.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='RV Subject'>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  value={rvBlock.rvSubject}
                  onChange={(value) => setRVBlock({ ...rvBlock, rvSubject: value })}
                >
                  {[...new Set(RV_OPTIONS.rv_data_subject)].map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={rvBlock.rvType === 'qdf' ? 'Layout QDF' : 'Layout EL'}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  value={rvBlock.rvLayout}
                  onChange={(value) => setRVBlock({ ...rvBlock, rvLayout: value })}
                >
                  {RV_LAYOUT.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='Button Text'>
                <Input
                  value={rvBlock.buttonText}
                  onChange={(e) => setRVBlock({ ...rvBlock, buttonText: e.target.value })}
                />
              </Form.Item>

              {rvBlock.rvType === 'el' ? (
                <>
                  <Form.Item label='Result limit'>
                    <InputNumber
                      value={rvBlock.resultLimit}
                      onChange={(value) => setRVBlock({ ...rvBlock, resultLimit: value })}
                    />
                  </Form.Item>

                  <Form.Item label='More button text'>
                    <Input
                      value={rvBlock.moreButtonText}
                      onChange={(e) => setRVBlock({ ...rvBlock, moreButtonText: e.target.value })}
                    />
                  </Form.Item>
                </>
              ) : null}
            </Collapse.Panel>

            {/* SHORTCODE */}
            <Collapse.Panel
              key='shortcode'
              header={<CollapseImageTooltip title='Shortcode' image={shortcodeImg} height='200px' />}
              id='shortcode'
            >
              <Form.Item label='Shortcode'>
                <Input value={shortcode} onChange={(e) => setShortcode(e.target.value)} />
              </Form.Item>
            </Collapse.Panel>

            {/* SECTION 1 */}
            <Collapse.Panel
              key='section 1'
              header={<CollapseImageTooltip title='Section 1' image={section2WidgetImg} height='310px' />}
              id='section1ID'
            >
              <Form.Item label='Heading'>
                <Input value={section1H1} onChange={(e) => setSection1H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section2Text'>Text:</label>
              <Editor
                content={section1Text}
                setContent={setSection1Text}
                constants={suggestionList}
                name='section1Text'
                tooltipImage={section2Img}
                elemID='section1ID'
              />

              <Divider />

              <div style={{ display: 'flex' }}>
                <Table
                  bordered
                  dataSource={avgSalaryWidget}
                  columns={[
                    {
                      title: 'DEGREE',
                      dataIndex: ['degree'],
                      key: 'degree',
                    },
                    {
                      title: 'AVG. SALARY',
                      dataIndex: ['salary'],
                      key: 'salary',
                    },
                  ]}
                  rowKey={(record) => record.degree}
                  pagination={false}
                  style={{ width: '50%' }}
                />

                <Divider type='vertical' style={{ height: '280px' }} />

                <Table
                  bordered
                  dataSource={avgTuition}
                  columns={[
                    {
                      title: 'TYPE',
                      dataIndex: ['type'],
                      key: 'type',
                    },
                    {
                      title: 'TUITION',
                      dataIndex: ['tuition'],
                      key: 'tuition',
                    },
                  ]}
                  rowKey={(record) => record.type}
                  pagination={false}
                  style={{ width: '50%' }}
                />
              </div>
            </Collapse.Panel>

            {/* SECTION 2 => MOST POPULAR PROGRAMS BY STATE */}
            <Collapse.Panel
              key='section 2'
              header={<CollapseImageTooltip title='Section 2' image={section4Img} height='310px' />}
              id='section2ID'
            >
              <Form.Item label='Heading'>
                <Input value={section2H1} onChange={(e) => setSection2H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section2Text'>Text:</label>
              <Editor
                content={section2Text}
                setContent={setSection2Text}
                constants={suggestionList}
                name='section2Text'
                tooltipImage={section4Img}
                elemID='section2ID'
              />

              <Divider />

              <Table
                bordered
                dataSource={mostPopularPrograms}
                columns={[
                  {
                    title: 'CIPCODE',
                    dataIndex: ['_id'],
                    key: '_id',
                  },
                  {
                    title: 'IPEDS PROGRAM TITLE',
                    dataIndex: ['title'],
                    key: 'title',
                  },
                  {
                    title: 'NEW TITLE',
                    dataIndex: ['newTitle'],
                    key: 'newTitle',
                  },
                  {
                    title: 'GRADUATES',
                    dataIndex: ['totalConferrals'],
                    key: 'totalConferrals',
                  },
                ]}
                rowKey={(record) => record._id}
                pagination={false}
                style={{ width: '80%' }}
              />
            </Collapse.Panel>

            {/* CAREERS (HIGHEST GROWTH) */}
            <Collapse.Panel
              key='careers with the highest growth'
              header={<CollapseImageTooltip title='Fastest growing careers' image={careerImg} height='340px' />}
              id='fastestGrowingCareers'
            >
              <Table
                bordered
                dataSource={fastestGrowingCareers}
                columns={[
                  {
                    title: 'CAREER NAME',
                    dataIndex: ['careerName'],
                    key: 'careerName',
                  },
                  {
                    title: 'OCC CODE',
                    dataIndex: ['occCode'],
                    key: 'occCode',
                  },
                  {
                    title: 'GROWTH INCREASE',
                    dataIndex: ['estimatedIncreasePerc'],
                    key: 'estimatedIncreasePerc',
                  },
                ]}
                rowKey={(record) => record.degree}
                pagination={false}
                style={{ width: '50%' }}
              />
            </Collapse.Panel>

            {/* SECTION 3 */}
            <Collapse.Panel
              key='section 3'
              header={<CollapseImageTooltip title='Section 3' image={section5Img} height='390px' />}
              id='section3ID'
            >
              <Form.Item label='Heading'>
                <Input value={section3H1} onChange={(e) => setSection3H1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='section3Text'>Text:</label>
              <Editor
                content={section3Text}
                setContent={setSection3Text}
                constants={suggestionList}
                name='section3Text'
                tooltipImage={section2Img}
                elemID='section3ID'
              />

              <Divider />

              <Table
                bordered
                dataSource={bestMajors}
                columns={[
                  {
                    title: 'CIPCODE',
                    dataIndex: ['_id'],
                    key: '_id',
                  },
                  {
                    title: 'IPEDS PROGRAM TITLE',
                    dataIndex: ['title'],
                    key: 'title',
                  },
                  {
                    title: 'NEW TITLE',
                    dataIndex: ['newTitle'],
                    key: 'newTitle',
                  },
                  {
                    title: 'AVG. ECONOMIC SCORE',
                    dataIndex: ['avgES'],
                    key: 'avgES',
                  },
                ]}
                rowKey={(record) => record._id}
                pagination={false}
                style={{ width: '80%' }}
              />
            </Collapse.Panel>

            {/* SOCIAL MOBILITY SECTION */}
            <Collapse.Panel
              key='social mobility'
              header={
                <CollapseImageTooltip
                  title='Social Mobility'
                  image={section6Img}
                  height='150px'
                  width='210px'
                  imageHeight='140px'
                  imageWidth='200px'
                />
              }
              id='socialMobilityID'
            >
              <Form.Item label='Heading'>
                <Input value={socialMobilityH1} onChange={(e) => setSocialMobilityH1(e.target.value)} />
              </Form.Item>

              <Divider />

              <label htmlFor='socialMobilityText'>Text:</label>
              <Editor
                content={socialMobilityText}
                setContent={setSocialMobilityText}
                constants={suggestionList}
                name='socialMobilityText'
                tooltipImage={section6Img}
                elemID='socialMobilityID'
              />

              <Divider />

              <Table
                bordered
                dataSource={emiSchools}
                columns={[
                  {
                    title: 'UNITID',
                    dataIndex: ['unitid'],
                    key: 'unitid',
                  },
                  {
                    title: 'NAME',
                    dataIndex: ['name'],
                    key: 'name',
                  },
                  {
                    title: 'CITY, STATE',
                    dataIndex: ['city_state'],
                    key: 'city_state',
                  },
                  {
                    title: 'EMI',
                    dataIndex: ['EMI'],
                    key: 'EMI',
                  },
                  {
                    title: 'EMI economic score',
                    dataIndex: ['EMI_ES'],
                    key: 'EMI_ES',
                  },
                  {
                    title: 'Pell Percentage',
                    dataIndex: ['pellPerc'],
                    key: 'pellPerc',
                  },
                ]}
                rowKey={(record) => record.unitid}
                pagination={false}
              />
            </Collapse.Panel>

            {/* EXPLORE MORE COLLEGES */}
            <Collapse.Panel
              key='explore more colleges'
              header={
                <CollapseImageTooltip
                  title='Explore more colleges'
                  image={exploreMoreImg}
                  height='150px'
                  imageHeight='140px'
                  imageWidth='200px'
                  width='210px'
                />
              }
              id='exploreMoreColleges'
            >
              <Table
                bordered
                dataSource={exploreMoreSchools}
                columns={[
                  {
                    title: 'UNITID',
                    dataIndex: ['unitid'],
                    key: 'unitid',
                  },
                  {
                    title: 'NAME',
                    dataIndex: ['name'],
                    key: 'name',
                  },
                  {
                    title: 'CITY',
                    dataIndex: ['city'],
                    key: 'city',
                  },
                  {
                    title: 'LINK',
                    dataIndex: ['link'],
                    key: 'link',
                    render: (text) => (
                      <a href={text} target='_blank' rel='noopener noreferrer' style={{ color: '#1890ff' }}>
                        LINK
                      </a>
                    ),
                  },
                ]}
                rowKey={(record) => record.unitid}
                pagination={false}
              />
            </Collapse.Panel>
          </Collapse>

          <br></br>

          <Form.Item name='useNewTemplate' label='Use new template' valuePropName='checked'>
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} loading={working} />
          </Form.Item>

          <div style={{ display: 'flex' }}>
            <Form.Item style={{ marginTop: '10px' }}>
              <Button type='primary' htmlType='submit'>
                SAVE
              </Button>
            </Form.Item>

            <Button type='link' style={{ marginLeft: '10px', marginTop: '10px' }}>
              <a href={wpLink ? wpLink : '#'} target='_blank' rel='noopener noreferrer'>
                Link to WP page
              </a>
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default SingleStateEditPage;
