import React, { useState, useEffect, useContext } from 'react';
import { Spin, Button, Select, Input, Form, notification, Table, Row, Tag, Tooltip, Modal, Checkbox } from 'antd';
import { ApiOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../../config';
import CKEditor from '../../../components/base/CKEditor';
import { ExportToExcel } from '../../../components/csv';

const DEGREES = [
  { label: 'Certificate', value: 'Undergraduate Certificate or Diploma' },
  { label: "Associate's", value: "Associate's Degree" },
  { label: "Bachelor's", value: 'Bachelors Degree' },
  { label: "Master's", value: "Master's Degree" },
  { label: 'Doctoral', value: 'Doctoral Degree' },
  { label: 'First Professional Degree', value: 'First Professional Degree' },
];

const formItemLayout = {
  labelCol: {
    xs: {
      span: 16,
    },
    sm: {
      span: 2,
    },
  },
  wrapperCol: {
    xs: {
      span: 16,
    },
    sm: {
      span: 10,
    },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 16,
      offset: 0,
    },
    sm: {
      span: 10,
      offset: 2,
    },
  },
};

const initialForm = {
  degree: null,
  cipcode: null,
  unitids: [],
  cipcodes: null,
  multipleCips: false,
  sortBy: 'economicScore',
  isOnline: false,
};

const NewCustomTopList = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [title, setTitle] = useState(null);
  const [working, setWorking] = useState(false);
  const [cipcode, setCipcode] = useState(null);
  const [multipleCips, setMultipleCips] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [degree, setDegree] = useState(null);
  const [unitids, setUnitids] = useState([]);
  const [data, setData] = useState([]);
  const [fetchClicked, setFetchClicked] = useState(true);
  const [modal, setModal] = useState({ visible: false, unitid: null, name: null });
  const [description, setDescription] = useState('');
  const [descArr, setDescArr] = useState([]);
  const [sortBy, setSortBy] = useState('economicScore');
  const currentUser = useContext(UserContext);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/all-school-profiles-names`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentUser.data.token}` },
        });

        if (res && res.data) {
          setUnitids(res.data);
        }

        if (id) {
          const resID = await Axios.get(`${SERVER_URL}/rankings-custom-top-lists/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentUser.data.token}` },
          });

          if (resID && resID.data) {
            setDegree(resID.data.degree);
            setCipcode(resID.data.cipcode);
            form.setFieldsValue({
              cipcode: resID.data.cipcode,
              degree: resID.data.degree,
              title: resID.data.title,
              unitids: resID.data.items.map((item) => item.unitid),
              cipcodes:
                resID.data.cipcodes && resID.data.cipcodes.length > 0
                  ? resID.data.cipcodes.map((cip) => ({ cip }))
                  : null,
              multipleCips: resID.data.multipleCips,
              sortBy: resID.data.sortBy ? resID.data.sortBy : 'economicScore',
              isOnline: resID.data.online,
            });
            setTitle(resID.data.title);
            setData(resID.data.items);
            setMultipleCips(resID.data.multipleCips);
            setDescArr(
              resID.data.items
                .filter((item) => item && item.description)
                .map((item) => ({ unitid: item.unitid, description: item.description })),
            );
            setSortBy(resID.data.sortBy ? resID.data.sortBy : 'economicScore');
            setIsOnline(resID.data.online);
          }
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error.message);
        notification.error({
          message: 'Error',
          description: 'Fetching all unitids for UNITIDs dropdown failed!',
          placement: 'bottomRight',
        });
      }
    };

    fetchData();
  }, [currentUser]);

  const GENERAL_COLUMNS = [
    {
      title: 'RANK',
      dataIndex: ['rank'],
      key: 'rank',
    },
    {
      title: 'UNITID',
      dataIndex: ['unitid'],
      key: 'unitid',
    },
    {
      title: 'NAME',
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: 'STATE',
      dataIndex: ['state'],
      key: 'state',
    },
    {
      title: 'CITY',
      dataIndex: ['city'],
      key: 'city',
    },
  ];

  const DEGREE_COLUMNS =
    form.getFieldValue('degree') !== "Master's Degree" &&
    form.getFieldValue('degree') !== 'First Professional Degree' &&
    form.getFieldValue('degree') !== 'Doctoral Degree'
      ? [
          {
            title: 'PAYBACK',
            dataIndex: ['payback'],
            key: 'payback',
            render: (value) => (value ? value.toFixed(2) : 'N/A'),
          },
          {
            title: 'COST',
            dataIndex: ['cost'],
            key: 'cost',
            render: (value, record) => value ?? 'N/A',
          },
        ]
      : [
          {
            title: 'DEBT',
            dataIndex: ['debt'],
            key: 'debt',
            render: (value) => (value ? value : 'N/A'),
          },
          {
            title: 'DEBT TO EARN RATIO',
            dataIndex: ['debtEarnRatio'],
            key: 'debtEarnRatio',
            render: (value) => (value && typeof value === 'number' ? value.toFixed(2) : 'N/A'),
          },
        ];

  const columns =
    sortBy !== 'acc_rate_medical'
      ? [
          ...GENERAL_COLUMNS,
          {
            title: 'EARNINGS',
            dataIndex: ['earnings'],
            key: 'earnings',
            render: (value, record) => (value ? value.toFixed(0) : 'N/A'),
          },
          ...DEGREE_COLUMNS,
          {
            title: 'GRAD RATE',
            dataIndex: ['gradRate'],
            key: 'gradRate',
            align: 'center',
            render: (value, record) => {
              return value ? (
                <Tag color={record.belowAverageGR ? 'red' : 'green'}>{`${value.toFixed(0)}%`}</Tag>
              ) : (
                'N/A'
              );
            },
          },
          {
            title: 'EARNINGPLUS',
            dataIndex: ['earningsPlus'],
            key: 'earningsPlus',
            render: (value) => (value ? value.toFixed(0) : 'N/A'),
          },
          {
            title: 'ECONOMIC SCORE',
            dataIndex: ['economicScore'],
            key: 'economicScore',
            render: (value) => (value ? value.toFixed(2) : 'N/A'),
          },
        ]
      : [
          ...GENERAL_COLUMNS,
          {
            title: 'APPLICATIONS',
            dataIndex: ['applications'],
            key: 'applications',
            render: (text) => (text && typeof text === 'number' ? text.toLocaleString('en-US') : 'N/A'),
          },
          {
            title: 'ENROLLED',
            dataIndex: ['enrolled'],
            key: 'enrolled',
            render: (text) => (text && typeof text === 'number' ? text.toLocaleString('en-US') : 'N/A'),
          },
          {
            title: 'ACCEPTANCE RATE',
            dataIndex: ['acceptanceRate'],
            key: 'acceptanceRate',
            render: (text) => (text ? `${text}%` : 'N/A'),
          },
        ];

  columns.push(
    {
      title: 'Description',
      key: 'description',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Edit description' placement='topLeft'>
                <EditOutlined
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => {
                    setModal({
                      visible: true,
                      unitid: record.unitid,
                      name: record.name,
                    });
                    setDescription(record.description ? record.description : '');
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Tooltip title='Remove school' placement='topLeft'>
                <DeleteOutlined
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={() => {
                    // schools
                    const newData = data.filter((item) => item.unitid !== record.unitid);
                    newData.forEach((item, index) => {
                      if (item.rank) {
                        item.rank = index + 1;
                      }
                    });
                    setData([...newData]);

                    // schools inside a main select
                    const selectedUnitids = form.getFieldValue('unitids');
                    const newUnitids = selectedUnitids.filter((item) => item !== record.unitid);
                    form.setFieldsValue({ ...form.getFieldsValue(), unitids: newUnitids });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  );

  const handleForm = async (values) => {
    if (degree && !cipcode && !form.getFieldValue('cipcodes')) {
      notification.error({
        message: 'Error',
        description: 'Missing cipcodes entry',
        placement: 'bottomRight',
      });
      return;
    }

    if (
      form.getFieldValue('cipcodes') &&
      form.getFieldValue('cipcodes').length !== 0 &&
      form.getFieldValue('cipcodes').length < 2
    ) {
      notification.error({
        message: 'Error',
        description: 'Minimum number of cipcodes is 2',
        placement: 'bottomRight',
      });
      return;
    }

    if (degree && sortBy === 'acc_rate_medical') {
      notification.error({
        message: 'Error',
        description: 'Sorting by acceptance rate is allowed only on institution level.',
        placement: 'bottomRight',
      });
      return;
    }

    let urlQuery = '';
    if (values.degree) urlQuery += `&degree=${values.degree}`;
    if (values.cipcode) urlQuery += `&cipcode=${values.cipcode}`;
    if (values.sortBy) urlQuery += `&sortBy=${values.sortBy}`;
    if (values.isOnline) urlQuery += `&online=${values.isOnline}`;

    if (values.cipcodes && values.cipcodes.length > 0) {
      values.cipcodes = values.cipcodes.map((cip) => cip.cip);
    }

    try {
      setWorking(true);

      const res = await Axios.post(
        `${SERVER_URL}/rankings-custom-top-lists${urlQuery ? '?' + urlQuery : ''}`,
        { unitids: values.unitids, cipcodes: values.cipcodes },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentUser.data.token}` } },
      );

      if (res.data) {
        if (res.data.message) {
          notification.error({
            message: 'Error',
            description: res.data.message,
            placement: 'bottomRight',
          });
          return;
        }

        if (descArr.length > 0) {
          res.data.forEach((item1) => {
            const foundItem = descArr.find((item2) => item2.unitid === item1.unitid);
            if (foundItem) {
              item1.description = foundItem.description;
            }
          });
        }
        setData(res.data);
        setFetchClicked(true);
      }

      setWorking(false);
    } catch (error) {
      setWorking(false);
      console.log(error.response.data.message);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const handleSave = async () => {
    const formValues = form.getFieldsValue();

    const body = {
      items: data,
      title: formValues.title,
      degree: formValues.degree ? formValues.degree : null,
      cipcode: formValues.cipcode ? formValues.cipcode : null,
      isProgramLevel: formValues.degree && formValues.cipcode ? true : false,
      cipcodes:
        formValues.cipcodes && formValues.cipcodes.length > 0 ? formValues.cipcodes.map((cip) => cip.cip) : null,
      multipleCips,
      sortBy: formValues.sortBy,
      online: formValues.isOnline,
    };

    const route = id ? `/rankings-custom-top-lists/${id}` : '/rankings-custom-top-lists-save';
    const method = id ? 'patch' : 'post';

    try {
      setWorking(true);

      await Axios[method](`${SERVER_URL}${route}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentUser.data.token}` },
      });

      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Top list created.',
        placement: 'bottomRight',
      });

      history.push('/admin/rankings/custom-top-lists');
    } catch (error) {
      setWorking(false);
      console.log(error.message);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const onOk = () => {
    const newData = data.map((item) => {
      if (item.unitid === modal.unitid) {
        item.description = description;
      }
      return item;
    });
    setData([...newData]);
    setModal({ visible: false });
  };

  let filename = title ? `${title}_custom_top_list` : 'custom_top_list';

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Working...'>
        <div className='actions-block flex'>
          <Link to={`/admin/rankings/custom-top-lists`}>
            <Button type='primary' style={{ marginRight: '8px', float: 'left' }}>
              ALL CUSTOM TOP LISTS
            </Button>
          </Link>

          <ExportToExcel
            csvData={data}
            fileName={filename}
            propsToRemove={['_id']}
            disabled={!data || data.length === 0 || !title}
          />
        </div>

        <br></br>

        <div>
          <Form form={form} initialValues={initialForm} onFinish={handleForm} name='basic'>
            <Form.Item name='multipleCips' label='Use multiple cipcodes' style={{ marginLeft: '10px' }}>
              <Checkbox
                checked={multipleCips}
                onChange={(e) => {
                  setMultipleCips(e.target.checked);
                  setCipcode(null);
                  form.setFieldsValue({ cipcode: null, cipcodes: null });
                }}
              />
            </Form.Item>

            {multipleCips && (
              <Form.List name='cipcodes'>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Form.Item
                          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                          label={index === 0 ? 'Cipcodes' : ''}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please input cipcode or delete this field',
                              },
                            ]}
                            noStyle
                            name={[index, 'cip']}
                          >
                            <Input
                              placeholder='Cipcode'
                              style={{
                                width: '50%',
                              }}
                            />
                          </Form.Item>

                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className='dynamic-delete-button'
                              onClick={() => {
                                remove(field.name);
                                setFetchClicked(false);
                              }}
                              style={{ marginLeft: '10px', fontSize: '20px' }}
                            />
                          ) : null}
                        </Form.Item>
                      );
                    })}

                    <Form.Item style={{ marginLeft: '10px' }}>
                      <Button
                        type='default'
                        onClick={() => {
                          add();
                          setFetchClicked(false);
                        }}
                        style={{
                          width: '200px',
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add Cipcode
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}

            <Row gutter={[24, 24]} style={{ marginLeft: '10px' }}>
              {!multipleCips && (
                <Form.Item
                  name='cipcode'
                  label='Cipcode'
                  rules={[
                    {
                      required: !degree ? false : true,
                      message: 'Must enter cipcode if degree is selected',
                    },
                    {
                      type: 'string',
                      min: 2,
                      max: 7,
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      setCipcode(e.target.value);
                      setFetchClicked(false);
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item
                name='degree'
                label='Degree'
                style={{ marginLeft: '10px' }}
                rules={[
                  {
                    required: !cipcode && !multipleCips ? false : true,
                    message: 'Must select degree if cipcode is entered',
                  },
                ]}
              >
                <Select
                  allowClear
                  style={{ width: '200px', marginLeft: '1px' }}
                  onChange={(value) => {
                    setDegree(value);
                    setFetchClicked(false);
                    form.setFieldsValue({ sortBy: 'economicScore', isOnline: false });
                    setSortBy('economicScore');
                    setIsOnline(false);
                  }}
                >
                  {DEGREES.map(({ value, label }, index) => (
                    <Select.Option key={`${value}_${index}`} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='title'
                label='Title'
                style={{ marginLeft: '10px', width: '450px' }}
                rules={[
                  {
                    required: true,
                    message: 'Must enter title for the top list',
                  },
                ]}
              >
                <Input onChange={(e) => setTitle(e.target.value)} />
              </Form.Item>
            </Row>

            <Row gutter={[24, 24]} style={{ marginLeft: '10px' }}>
              <Form.Item name='isOnline' label='Online'>
                <Checkbox
                  checked={isOnline}
                  onChange={(e) => {
                    setIsOnline(e.target.checked);
                    form.setFieldsValue({ isOnline: e.target.checked });
                    setFetchClicked(false);
                  }}
                  disabled={(!cipcode && !form.getFieldValue('cipcodes')) || !degree}
                />
              </Form.Item>
            </Row>

            <Form.Item
              name='unitids'
              label='UNITIDs'
              // style={{ width: '650px' }}
              rules={[{ required: true, message: 'Need to select at least 1 UNITID' }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp='children'
                mode='multiple'
                onChange={(values) => {
                  setFetchClicked(false);
                }}
              >
                {unitids.map(({ unitid, name }) => (
                  <Select.Option key={unitid} value={unitid} title={name}>
                    {unitid} - {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name='sortBy' label='Sort by' style={{ width: '400px' }}>
              <Select
                showSearch
                defaultValue='economicScore'
                optionFilterProp='children'
                onChange={(value) => {
                  setFetchClicked(false);
                  setSortBy(value);
                }}
              >
                <Select.Option key={'economicScore_sort'} value={'economicScore'} title={'Economic Score'}>
                  Economic Score
                </Select.Option>

                <Select.Option key={'earnings_sort'} value={'earnings'} title={'Earnings'}>
                  Earnings
                </Select.Option>

                <Select.Option
                  key={'debt_sort'}
                  value={'debt'}
                  title={'Debt'}
                  disabled={
                    !degree ||
                    (degree !== "Master's Degree" &&
                      degree !== 'First Professional Degree' &&
                      degree !== 'Doctoral Degree')
                  }
                >
                  Debt
                </Select.Option>

                <Select.Option
                  key={'cost_sort'}
                  value={'cost'}
                  title={'Cost'}
                  disabled={
                    degree === "Master's Degree" ||
                    degree === 'First Professional Degree' ||
                    degree === 'Doctoral Degree'
                  }
                >
                  Cost
                </Select.Option>

                <Select.Option
                  key={'acc_rate_medical_sort'}
                  value={'acc_rate_medical'}
                  title={'Acceptance Rate (Medical Schools)'}
                  disabled={degree ? true : false}
                >
                  Acceptance Rate (Medical Schools)
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit'>
                <ApiOutlined />
                FETCH
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div>
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            dataSource={data}
            columns={columns}
            rowKey={(record) => record.unitid}
            pagination={{
              pageSize: 20,
              // total: total || 0,
              // current: page,
              // defaultCurrent: 1,
              // position: 'bottom',
              // showSizeChanger: false,
              // onChange: (page) => pageHandler(page),
              // hideOnSinglePage: true,
            }}
          />

          <Modal
            centered
            width='70%'
            visible={modal.visible}
            title={
              <p>
                Description for<strong> {modal.name}</strong>
              </p>
            }
            okText='SAVE'
            cancelText='CANCEL'
            maskClosable={false}
            onOk={onOk}
            onCancel={() => {
              setModal({ visible: false });
            }}
            confirmLoading={working}
          >
            <div>
              <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Description: </p>
              <CKEditor content={description} setContent={setDescription} />
            </div>
          </Modal>

          <Button
            type='primary'
            onClick={handleSave}
            disabled={
              !data ||
              data.length === 0 ||
              (!form.getFieldValue('cipcode') && !form.getFieldValue('cipcodes') && form.getFieldValue('degree')) ||
              ((form.getFieldValue('cipcode') || form.getFieldValue('cipcodes')) && !form.getFieldValue('degree')) ||
              (!form.getFieldValue('cipcode') &&
                form.getFieldValue('cipcodes') &&
                form.getFieldValue('cipcodes').length < 2 &&
                form.getFieldValue('degree')) ||
              !fetchClicked
            }
          >
            SAVE
          </Button>
        </div>
      </Spin>
    </div>
  );
};

export default NewCustomTopList;
